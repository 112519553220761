<template>
  <div class="content">
    <PageHeader title="Respostas" />
    <div class="page-content container-fluid">
      <div class="qt-block-ui" v-if="loading"></div>
      <template v-else>
        <div class="row">
          <div class="col-lg-12">
            <div class="card">
              <div class="card-header">
                Tags
              </div>
              <div class="card-body">
                <b-table striped hover :items="tags"></b-table>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="card">
              <label class="card-header" for="satisfaction">Avaliação do agente</label>
              <div class="card-body">
                <textarea class="form-control" v-model="form.satisfaction" id="satisfaction"></textarea>
              </div>
              <div class="card-footer">
                Avalia o usuário após o atendimento
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="card">
              <label class="card-header" for="thanks">Agradecimento *</label>
              <div class="card-body">
                <textarea class="form-control" v-model="form.thanks" id="thanks"></textarea>
              </div>
              <div class="card-footer">
                * Esta mensagem não aceita tags
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="card">
              <label class="card-header" for="reopen">Reabertura de sala</label>
              <div class="card-body">
                <textarea class="form-control" v-model="form.reopen" id="reopen"></textarea>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="card">
              <label class="card-header" for="widget">Mensagem no widget</label>
              <div class="card-body">
                <textarea class="form-control" v-model="form.widget" id="widget"></textarea>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="card">
              <label class="card-header" for="before_dept">Mensagem de apresentação</label>
              <div class="card-body">
                <textarea class="form-control" v-model="form.before_dept" id="before_dept"></textarea>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="card">
              <label class="card-header" for="auto_end">Quando a sala fechar automática</label>
              <div class="card-body">
                <textarea class="form-control" v-model="form.auto_end" id="auto_end"></textarea>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="card">
              <label class="card-header" for="dept_inv">Opção inválida</label>
              <div class="card-body">
                <textarea class="form-control" v-model="form.dept_inv" id="dept_inv"></textarea>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="card">
              <label class="card-header" for="dept_inv_fast">Opção inválida rápido</label>
              <div class="card-body">
                <textarea class="form-control" v-model="form.dept_inv_fast" id="dept_inv_fast"></textarea>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="card">
              <label class="card-header" for="client_end">Quando o cliente finalizar</label>
              <div class="card-body">
                <textarea class="form-control" v-model="form.client_end" id="client_end"></textarea>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <!-- <div class="card">
              <label class="card-header" for="service_end">Atendimento encerrado</label>
              <div class="card-body">
                <textarea class="form-control" v-model="form.service_end" id="service_end"></textarea>
              </div>
            </div> -->
            <div class="card">
              <label class="card-header" for="transfer">Transferir para outro agente</label>
              <div class="card-body">
                <textarea class="form-control" v-model="form.transfer" id="transfer"></textarea>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="card">
              <label class="card-header" for="transfer_department">Transferir para outro departamento</label>
              <div class="card-body">
                <textarea class="form-control" v-model="form.transfer_department" id="transfer_department"></textarea>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="card">
              <label class="card-header" for="return_queue">Retornar para fila</label>
              <div class="card-body">
                <textarea class="form-control" v-model="form.return_queue" id="return_queue"></textarea>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex flex-row-reverse">
          <button class="btn btn-primary" @click="save">Salvar</button>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
// import Emojis from '@/components/Emojis.vue';
import PageHeader from '@/components/PageHeader.vue';
// import { ToggleButton } from 'vue-js-toggle-button';
import axios from '@/plugins/axios';

export default {
  name: 'Responses',
  components: {
    // InfiniteLoading,
    // Emojis,
    PageHeader,
    // ToggleButton,
  },
  data() {
    return {
      loading: true,
      form: {
        satisfaction: '',
        thanks: '',
        transfer: '',
        reopen: '',
        widget: '',
        before_dept: '',
        auto_end: '',
        dept_inv: '',
        dept_inv_fast: '',
        client_end: '',
        // service_end: '',
        transfer_department: '',
        return_queue: '',
      },
      tags: [
        { tag: '[FOO]', descrição: 'Macdonald' },
      ],
    };
  },
  computed: {
    account() {
      return this.$store.state.account;
    },
  },
  mounted() {
    axios().get('webchat/responses').then((response) => {
      const form = this.form;
      const data = response.data;

      Object.keys(form).forEach((key) => {
        if (data[key]) form[key] = data[key];
      });

      return axios().get('webchat/tags').then((response) => {
        this.loading = false;
        this.tags = response.data;
      });
    }).catch((error) => {
      this.loading = false;
      console.error('responses', error);
    });
  },
  methods: {
    save() {
      this.loading = true;

      axios().put('webchat/responses', this.form).then((response) => {
        this.loading = false;
        console.log('responses', response);
      }).catch((error) => {
        this.loading = false;
        console.error('responses', error);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.qt-block-ui {
  min-height: 200px;
}

textarea {
  min-height: 95px;
}

.card-footer {
  font-size: 9pt;
  background: #eef4ff;
}
</style>
